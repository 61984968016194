.loginPageContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  & .loginPartition1 {
    display: flex;
    flex: 3;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  & .loginPartition2 {
    background-color: #ffffff;
    display: flex;
    flex: 2.5;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.leftPart {
  width: 100%;
  height: 100%;
  text-align: right;
  // background-color: rgb(178, 178, 178);
  background-image: url("./tmpImage/loginbg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  // box-shadow: inset -200px 0 200px 10px #ffffff;
  box-shadow: 10px 0 100px 20px rgb(231, 231, 231);
}

.rightPart {
  height: fit-content;
  padding: 30px;
  color: $darkgray;
  // background-color: rgb(122, 255, 144);
}

.loginFields {
  padding: 10px 0;
  & p {
    color: $darkgray;
  }
  & input {
    margin: 15px 0;
    padding: 10px 15px;
    width: 350px;
    min-width: 200px;
    max-width: 350px;
    border-radius: 3px;
    border: 1px solid #777777;
  }
}

.loginButton {
  background-color: #202020;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
  & p {
    color: white;
    text-decoration: none;
  }
}
