.columnContainer {
  justify-items: space-between;
  gap: 1.5rem;
  height: 80vh;
}

.pdlColumn1 {
  flex: 3;
}

.pdlColumn2 {
  flex: 2;
  height: 95%;
  & .subDiv {
    margin: 10px;
    height: 100%;
    flex: 1;
    border: 2px solid teal;
  }
}

.tablePdlHeader {
  width: 100%;
  height: 50px;
  background-color: lightblue;
  border-radius: 10px 10px 0 0;
}
