.column1 {
  flex: 6;
  // background-color: aquamarine;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
}

.column2 {
  flex: 4;
  // background-color: rgb(255, 149, 149);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.padalaFormCard {
  background-color: white;
  padding: 25px;
  flex: 1;
}

.padalaForm {
  display: flex;
  flex-wrap: wrap;
}

.padalaSummaryCard {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 0 0 20px;
  padding: 30px;
}

.padalaSummaryColumnContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.padalaSummaryColumn {
  width: 50%;
  & div {
    padding: 5px 0;
    & p {
      padding: 3px 0 0 0;
    }
  }
}

.padalaAmount {
  border: none;
  background-color: #ececec;
  height: 100px;
  width: 100%;
  min-width: 350px;
  max-width: 450px;
  margin: 15px 0;
  padding: 0 15px;
}

.successModal {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  // background-color: rgb(255, 255, 255);
  background: linear-gradient(to bottom right, lime, skyblue);
  position: absolute;
  outline: none;
  box-shadow: 0 10px 50px #c7c7c7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  height: 400px;
  width: 700px;
  & .alertCloseButton {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }
  & p {
    font-size: 70px;
    // background: linear-gradient(to right, lime, skyblue);
    // -webkit-background-clip: text;
    color: white;
  }
}
