// @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");

$font-weights: (
  "light": 300,
  "regular": 400,
  "medium": 500,
  "semibold": 600,
  "bold": 700,
  "extrabold": 800,
  "black": 900,
);

$font-sizes: (
  "xsm": 0.75rem,
  "sm": 0.8rem,
  "m": 0.9rem,
  "r": 1rem,
  "l": 1.25rem,
  "xl": 1.5rem,
  "xxl": 1.75rem,
);

$font-family: "Figtree", sans-serif;

@each $name, $weight in $font-weights {
  .#{$name} {
    font-family: $font-family;
    font-weight: $weight;
    font-style: normal;
  }
  .#{$name}-i {
    font-family: $font-family;
    font-weight: $weight;
    font-style: italic;
  }
}

@each $size-name, $size-value in $font-sizes {
  .#{$size-name} {
    font-family: $font-family;
    font-size: $size-value;
  }
}
