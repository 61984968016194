.header {
  display: flex;
  flex-direction: row;
  background: white;
  color: white;
  padding: 10px;
  height: 5vh;
  align-items: center;
}

.img-placeholder {
  margin: 0 20px;
  background-image: url("./tmpImage/vector_dp.jpg");
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.headerIcon {
  margin: 0 10px;
  height: 24px;
  color: #222222;
  transition: all 0.2s linear;
}
