.merchantTableContainer {
  flex: 1;
  background-color: white;
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
}

.merchantTable {
  width: 100%;
  border-collapse: collapse;
  & tr:hover {
    background-color: #f5f5f5;
  }
  & th,
  td {
    padding: 10px;
  }
}

.merchantTableHeaderContainer {
  background-color: #ffffff;
  height: 50px;
  & th {
    top: 0;
    position: sticky;
    background-color: white;
    &::after {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #545454;
    }
  }
}

.merchantTableFooter {
  background-color: #e6e6e6;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  border-radius: 0 0 5px 5px;
  & div {
    height: 100%;
    align-content: center;
    flex: 1;
  }
}

.editMerchantButton {
  background-color: #2054ff;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #193fba;
    // box-shadow: inset 0 0 0 2px white;
  }
}

.addMerchantCard {
  display: flex;
  flex-direction: row;
  height: auto;
  gap: 25px;
  & div {
    flex: 1;
    background-color: white;
  }
  & .merchantForm {
    padding: 25px;
  }
}

.merchantPhotoContainer {
  margin: 25px 0;
  height: 400px;
  & img {
    height: 350px;
  }
}

.viewMerchantModal {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  position: absolute;
  outline: none;
  box-shadow: 0 10px 50px #c7c7c7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  height: auto;
  width: auto;
  & .alertCloseButton {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
  }
  & p {
    padding: 5px;
  }
  & img {
    height: 200px;
    border-radius: 100%;
  }
}
