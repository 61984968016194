$darkblue: #002347;
$blue: #003366;
$lightblue: #003f7d;
$lightorange: #ff8e00;
$orange: #fd7702;
$deeporange: #ff5003;
$darkgray: #222222;
$offwhite: #fcfcfc;

$colors: (
  "darkblue": $darkblue,
  "blue": $blue,
  "lightblue": $lightblue,
  "lightorange": $lightorange,
  "orange": $orange,
  "offwhite": $offwhite,
  "deeporange": $deeporange,
  "white": #fff,
  "darkgray": $darkgray,
  "black": #000,
);

@each $key, $val in $colors {
  .t-#{$key} {
    color: $val;
  }
  .bg-#{$key} {
    background-color: $val;
  }
}
