.sidebar {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0.75rem;
  justify-items: center;
  height: calc(100vh - 1.5rem); //change rem double of padding
  width: 60px;
  transition: all 0.2s ease-in-out;
  // &:hover {
  //   // width: 12vw;
  //   width: 250px;
  //   & .link {
  //     flex: 8;
  //     opacity: 1;
  //     height: auto;
  //   }
  //   & .icon {
  //     flex: 2.5;
  //   }
  // }
}

.active {
  display: flex;
  flex-direction: column;
  background: $offwhite;
  padding: 0.75rem;
  justify-items: center;
  height: calc(100vh - 1.5rem); //change rem double of padding
  width: 250px;
  transition: all 0.2s ease-in-out;
  &.sidebar .link {
    flex: 8;
    opacity: 1;
    height: auto;
  }
  &.sidebar .icon {
    flex: 2.5;
  }
}

.sidebarItem {
  //   background-color: red;
  overflow: hidden;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: $darkgray;
    // background-color: $deeporange;
    .link,
    .icon {
      color: #e9e9e9;
      // color: $deeporange;
    }
  }
  .icon {
    // background-color: blue;
    height: 24px;
    // width: 100%;
    flex: 2;
    color: #222222;
    align-self: center;
    transition: all 0.2s linear;
  }
  .link {
    white-space: nowrap;
    opacity: 0;
    flex: 0;
    overflow: hidden;
    color: #222222;
    align-content: center;
    transition: all 0.2s ease-in-out;
  }
}

.sidebarItemActive {
  overflow: hidden;
  margin-bottom: 6px;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  text-decoration: none;
}

.line {
  height: 1px;
  border-radius: 10px;
  margin: 0 15px 0;
  background-color: #cacaca;
}

.line2 {
  height: 1px;
  border-radius: 10px;
  background-color: #cacaca;
}

.logo-placeholder {
  display: flex;
  overflow: hidden;
  align-items: center;
  // flex: 0.1;
  padding: 10px;
  // background-color: #dddddd;
  & .link {
    flex: 1;
    color: #ec4e0f;
    font-size: xx-large;
    text-decoration: none;
    white-space: nowrap;
    text-align: center;
  }
}
