.dropdown1 {
  padding: 0 10px;
  height: 40px;
  width: 130px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #e2e2e2;
  outline-color: #e2e2e2;
  align-content: center;
}

.searchboxContainer {
  // margin: 0 5px 0 0;
  justify-content: space-around;
  height: 30px;
  width: 350px;
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
  border-style: hidden;
  padding: 5px;
}

.searchbox {
  height: 30px;
  background-color: #ffffff;
  outline: none;
  border: none;
  flex: 1;
}

.button1 {
  height: 30px;
  display: inline-flex;
  margin: 0 5px;
  padding: 5px 0;
  cursor: pointer;
  border-radius: 10px;
  background-color: #222222;
  & p {
    margin: 0 15px 0;
  }
  & .btnIcon1 {
    margin: 0 0 0 10px;
  }
}

.button2 {
  margin: 0 5px;
  padding: 5px 0;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #c4c4c4;
  & p {
    margin: 0 15px 0;
  }
}

.button2Component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  height: 35px;
  align-content: center;
  background-color: #222222;
  cursor: pointer;
  // border: 2px solid #c4c4c4;
  border-radius: 5px;
  & p {
    margin: 0 15px 0;
  }
  &:hover {
    box-shadow: inset 0 0 0 3px #f5f5f5;
  }
}

@mixin modalButton {
  display: inline-flex;
  outline: none;
  color: white;
  border: none;
  padding: 6px 20px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0 0 2px #808080;
  }
}

.modalButton1 {
  @include modalButton;
  background-color: teal;
}

.modalButton2 {
  @include modalButton;
  background-color: orangered;
}

.checkboxContainer {
  width: 70px;
  text-align: center;
  align-content: center;
}

.checkbox {
  height: 18px;
  width: 18px;
  background-color: #f5f5f5;
  border-style: solid;
  border-color: #222222;
  border-radius: 0.25rem;
  border-width: 1.5px;
  cursor: pointer;
}

.form1 {
  // outline: none;
  border: none;
  background-color: #ececec;
  height: 45px;
  width: 350px;
  min-width: 200px;
  max-width: 450px;
  margin: 15px 0;
  padding: 0 15px;
}
