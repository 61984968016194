@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.t-darkblue {
  color: #002347;
}

.bg-darkblue {
  background-color: #002347;
}

.t-blue {
  color: #003366;
}

.bg-blue {
  background-color: #003366;
}

.t-lightblue {
  color: #003f7d;
}

.bg-lightblue {
  background-color: #003f7d;
}

.t-lightorange {
  color: #ff8e00;
}

.bg-lightorange {
  background-color: #ff8e00;
}

.t-orange {
  color: #fd7702;
}

.bg-orange {
  background-color: #fd7702;
}

.t-offwhite {
  color: #fcfcfc;
}

.bg-offwhite {
  background-color: #fcfcfc;
}

.t-deeporange {
  color: #ff5003;
}

.bg-deeporange {
  background-color: #ff5003;
}

.t-white {
  color: #fff;
}

.bg-white {
  background-color: #fff;
}

.t-darkgray {
  color: #222222;
}

.bg-darkgray {
  background-color: #222222;
}

.t-black {
  color: #000;
}

.bg-black {
  background-color: #000;
}

.light {
  font-family: "Figtree", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.light-i {
  font-family: "Figtree", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.regular {
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.regular-i {
  font-family: "Figtree", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.medium {
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.medium-i {
  font-family: "Figtree", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.semibold {
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.semibold-i {
  font-family: "Figtree", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.bold {
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.bold-i {
  font-family: "Figtree", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.extrabold {
  font-family: "Figtree", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.extrabold-i {
  font-family: "Figtree", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.black {
  font-family: "Figtree", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.black-i {
  font-family: "Figtree", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.xsm {
  font-family: "Figtree", sans-serif;
  font-size: 0.75rem;
}

.sm {
  font-family: "Figtree", sans-serif;
  font-size: 0.8rem;
}

.m {
  font-family: "Figtree", sans-serif;
  font-size: 0.9rem;
}

.r {
  font-family: "Figtree", sans-serif;
  font-size: 1rem;
}

.l {
  font-family: "Figtree", sans-serif;
  font-size: 1.25rem;
}

.xl {
  font-family: "Figtree", sans-serif;
  font-size: 1.5rem;
}

.xxl {
  font-family: "Figtree", sans-serif;
  font-size: 1.75rem;
}


.sidebar {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0.75rem;
  justify-items: center;
  height: calc(100vh);
  width: 60px;
  transition: all 0.2s ease-in-out;
}

.active {
  display: flex;
  flex-direction: column;
  background: #fcfcfc;
  padding: 0.75rem;
  justify-items: center;
  height: calc(100vh);
  width: 270px;
  transition: all 0.2s ease-in-out;
}
.active.sidebar .link {
  flex: 8;
  opacity: 1;
  height: auto;
}
.active.sidebar .icon {
  flex: 2.5;
}

.sidebarItem {
  overflow: hidden;
  margin-bottom: 6px;
  display: flex;
  border-radius: 5px;
  padding: 5px;
  height: 50px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.sidebarItem:hover {
  background-color: #4b80b9;
  color: #e9e9e9;
}
.sidebarItem:hover .link,
.sidebarItem:hover .icon {
  color: #e9e9e9;
}
.sidebarItem .icon {
  height: 24px;
  flex: 2;
  color: #222222;
  align-self: center;
  transition: all 0.2s linear;
}
.sidebarItem .link {
  white-space: nowrap;
  opacity: 0;
  flex: 0;
  overflow: hidden;
  color: #222222;
  align-content: center;
  transition: all 0.2s ease-in-out;
}

.sidebarItem.activated{
  background-color: #4b80b9;
  color: #ffffff !important;
}

.sidebarItem.activated .link,
.sidebarItem.activated .icon {
  color: #e9e9e9;
}

.sidebarItemActive {
  overflow: hidden;
  margin-bottom: 6px;
  display: flex;
  border-radius: 5px;
  padding: 10px;
  height: 30px;
  text-decoration: none;
}

.line {
  height: 1px;
  border-radius: 10px;
  margin: 0 15px 0;
  background-color: #cacaca;
}

.line2 {
  height: 1px;
  border-radius: 10px;
  background-color: #cacaca;
}

.logo-placeholder {
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 10px;
}
.logo-placeholder .link {
  flex: 1;
  color: #ec4e0f;
  font-size: xx-large;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
}

.header {
  display: flex;
  flex-direction: row;
  background: white;
  color: white;
  padding: 10px;
  height: 5vh;
  align-items: center;
}

.img-placeholder {
  margin: 0 20px;
  background-image: url("./tmpImage/vector_dp.jpg");
  background-size: cover;
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.headerIcon {
  margin: 0 10px;
  height: 24px;
  color: #222222;
  transition: all 0.2s linear;
}

.dropdown1 {
  padding: 0 10px;
  height: 40px;
  width: 130px;
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: #e2e2e2;
  outline-color: #e2e2e2;
  align-content: center;
}

.searchboxContainer {
  justify-content: space-around;
  height: 30px;
  width: 350px;
  border-radius: 10px;
  border: none;
  background-color: #ffffff;
  border-style: hidden;
  padding: 5px;
}

.searchbox {
  height: 30px;
  background-color: #ffffff;
  outline: none;
  border: none;
  flex: 1;
}

.button1 {
  height: 30px;
  display: inline-flex;
  margin: 0 5px;
  padding: 5px 0;
  cursor: pointer;
  border-radius: 10px;
  background-color: #222222;
}
.button1 p {
  margin: 0 15px 0;
}
.button1 .btnIcon1 {
  margin: 0 0 0 10px;
}

.button2 {
  margin: 0 5px;
  padding: 5px 0;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: #c4c4c4;
}
.button2 p {
  margin: 0 15px 0;
}

.button2Component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  height: 35px;
  align-content: center;
  background-color: #222222;
  cursor: pointer;
  border-radius: 5px;
}
.button2Component p {
  margin: 0 15px 0;
}
.button2Component:hover {
  box-shadow: inset 0 0 0 3px #f5f5f5;
}

.modalButton1 {
  display: inline-flex;
  outline: none;
  color: white;
  border: none;
  padding: 6px 20px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  background-color: teal;
}
.modalButton1:hover {
  box-shadow: 0 0 0 2px #808080;
}

.modalButton2 {
  display: inline-flex;
  outline: none;
  color: white;
  border: none;
  padding: 6px 20px;
  border-radius: 5px;
  margin: 10px 10px;
  cursor: pointer;
  background-color: orangered;
}
.modalButton2:hover {
  box-shadow: 0 0 0 2px #808080;
}

.checkboxContainer {
  width: 70px;
  text-align: center;
  align-content: center;
}

.checkbox {
  height: 18px;
  width: 18px;
  background-color: #f5f5f5;
  border-style: solid;
  border-color: #222222;
  border-radius: 0.25rem;
  border-width: 1.5px;
  cursor: pointer;
}

.form1 {
  border: none;
  background-color: #ececec;
  height: 45px;
  width: 350px;
  min-width: 200px;
  max-width: 450px;
  margin: 15px 0;
  padding: 0 15px;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f0f2f5;
}

.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.content {
  display: flex;
  height: 95vh;
  flex-direction: column;
  overflow-y: auto;
}

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  background: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

main {
  flex: 1;
  padding: 1.5rem;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

p {
  margin: 0;
}

.spacer {
  flex: 1;
}

.spacer-v {
  height: 30px;
}

.textCenter {
  text-align: center;
}

.loginPageContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.loginPageContainer .loginPartition1 {
  display: flex;
  flex: 3;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loginPageContainer .loginPartition2 {
  background-color: #ffffff;
  display: flex;
  flex: 2.5;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.leftPart {
  width: 100%;
  height: 100%;
  text-align: right;
  background-image: url("./tmpImage/loginbg3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 10px 0 100px 20px rgb(231, 231, 231);
}

.rightPart {
  height: fit-content;
  padding: 30px;
  color: #222222;
}

.loginFields {
  padding: 10px 0;
}
.loginFields p {
  color: #222222;
}
.loginFields input {
  margin: 15px 0;
  padding: 10px 15px;
  width: 350px;
  min-width: 200px;
  max-width: 350px;
  border-radius: 3px;
  border: 1px solid #777777;
}

.loginButton {
  background-color: #202020;
  padding: 10px;
  text-align: center;
  border-radius: 3px;
}
.loginButton p {
  color: white;
  text-decoration: none;
}

.column1 {
  flex: 6;
  display: flex;
  flex-direction: column;
  align-content: center;
  height: 100%;
}

.column2 {
  flex: 4;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.padalaFormCard {
  background-color: white;
  padding: 25px;
  flex: 1;
}

.padalaForm {
  display: flex;
  flex-wrap: wrap;
}

.padalaSummaryCard {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 0 0 20px;
  padding: 30px;
}

.padalaSummaryColumnContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.padalaSummaryColumn {
  width: 50%;
}
.padalaSummaryColumn div {
  padding: 5px 0;
}
.padalaSummaryColumn div p {
  padding: 3px 0 0 0;
}

.padalaAmount {
  border: none;
  background-color: #ececec;
  height: 100px;
  width: 100%;
  min-width: 350px;
  max-width: 450px;
  margin: 15px 0;
  padding: 0 15px;
}

.successModal {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom right, lime, skyblue);
  position: absolute;
  outline: none;
  box-shadow: 0 10px 50px #c7c7c7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  height: 400px;
  width: 700px;
}
.successModal .alertCloseButton {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.successModal p {
  font-size: 70px;
  color: white;
}

.contentHeader {
  align-items: center;
}

.filterDiv {
  display: flex;
}

.tableContainer {
  flex: 1;
  background-color: white;
  border-radius: 5px 5px 0 0;
  overflow-y: auto;
}
.tableContainer table {
  width: 100%;
  border-collapse: collapse;
}
.tableContainer table tr:hover {
  background-color: #f5f5f5;
}
.tableContainer table th,
.tableContainer table td {
  padding: 10px;
}
.tableContainer table th {
  top: 0;
  position: sticky;
  background-color: white;
}
.tableContainer table th::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #545454;
}

th,
td {
  border-bottom: 1px solid #ddd;
}

th {
  text-align: left;
}

.imageCell {
  align-content: center;
}

.imageContainer {
  display: flex;
  align-items: center;
}
.imageContainer img {
  width: 40px;
}

.instock {
  width: 110px;
  align-content: center;
  background-color: #31d100;
  color: white;
  border-style: solid;
  border-color: #31d100;
  border-width: 2px;
  border-radius: 100px;
  height: 25px;
}

.outofstock {
  width: 110px;
  align-content: center;
  background-color: #d13100;
  color: white;
  border-style: solid;
  border-color: #d13100;
  border-width: 2px;
  border-radius: 100px;
  height: 25px;
}

.addNewProductModal {
  position: absolute;
  justify-content: center;
  padding: 25px 70px;
  border: 2px solid #cccccc;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  background-color: white;
}

.modalFormRow {
  width: 100%;
  height: 30px;
  padding: 5px;
}
.modalFormRow label {
  width: 150px;
}
.modalFormRow input,
.modalFormRow select {
  width: 250px;
  height: 20px;
  padding: 5px;
  outline: none;
  border: 1px solid #707070;
  border-radius: 5px;
}

.merchantTableContainer {
  flex: 1;
  background-color: white;
  border-radius: 10px 10px 0 0;
  overflow-y: auto;
}

.merchantTable {
  width: 100%;
  border-collapse: collapse;
}
.merchantTable tr:hover {
  background-color: #f5f5f5;
}
.merchantTable th,
.merchantTable td {
  padding: 10px;
}

.merchantTableHeaderContainer {
  background-color: #ffffff;
  height: 50px;
}
.merchantTableHeaderContainer th {
  top: 0;
  position: sticky;
  background-color: white;
}
.merchantTableHeaderContainer th::after {
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #545454;
}

.merchantTableFooter {
  background-color: #e6e6e6;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  border-radius: 0 0 5px 5px;
}
.merchantTableFooter div {
  height: 100%;
  align-content: center;
  flex: 1;
}

.editMerchantButton {
  background-color: #2054ff;
  padding: 5px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.editMerchantButton:hover {
  background-color: #193fba;
}

.addMerchantCard {
  display: flex;
  flex-direction: row;
  height: auto;
  gap: 25px;
}
.addMerchantCard div {
  flex: 1;
  background-color: white;
}
.addMerchantCard .merchantForm {
  padding: 25px;
}

.merchantPhotoContainer {
  margin: 25px 0;
  height: 400px;
}
.merchantPhotoContainer img {
  height: 350px;
}

.viewMerchantModal {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgb(255, 255, 255);
  position: absolute;
  outline: none;
  box-shadow: 0 10px 50px #c7c7c7;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  height: auto;
  width: auto;
}
.viewMerchantModal .alertCloseButton {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
.viewMerchantModal p {
  padding: 5px;
}
.viewMerchantModal img {
  height: 200px;
  border-radius: 100%;
}

.columnContainer {
  justify-items: space-between;
  gap: 1.5rem;
  height: 80vh;
}

.pdlColumn1 {
  flex: 3;
}

.pdlColumn2 {
  flex: 2;
  height: 95%;
}
.pdlColumn2 .subDiv {
  margin: 10px;
  height: 100%;
  flex: 1;
  border: 2px solid teal;
}

.tablePdlHeader {
  width: 100%;
  height: 50px;
  background-color: lightblue;
  border-radius: 10px 10px 0 0;
}

.dropDown{
  width: 100%;
  border-radius: 5px;
  height: 35px;
  margin-top: 15px;
  background-color: #ececec;
  font-family: "Figtree", sans-serif;
  font-size: 1rem;
  padding-left: 10px;
}

.jmodal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.jmodal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 350px;
}

.jmodal-content h2 {
  margin-top: 0;
}

.jmodal-content button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.jmodal-content button:hover {
  background-color: #0056b3;
}

.pdl-text{
  height: 30px;
  padding-left: 10px;
}

[role="columnheader"]{
  font-weight: bolder;
  font-size: 13px;
}

.pdl-info tr th{
  border: none;
}

.pdl-info tr td{
  border: none;
}

.inv-icon{
  width: 40px;
  height: 40px;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
}

.inv-icon.crumb{
  border: none ;
  width: 30px;
  height: 30px;
}

.inv-icon.active{
  background-color: #007bff;
  padding: 5px;
}

.inv-icon:hover{
  cursor: pointer;
}

.action-button{
  background-color: #007bff;
  border: 1px solid #d2d2d2;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
  color: white;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.action-button:hover{
  cursor: pointer;
  color: #007bff;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn.disabled,
.btn:disabled {
  opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(76, 175, 80, .5);
} 
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}