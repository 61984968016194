html,
body,
#root {
  height: 100%;
  margin: 0;
}

.layout {
  display: flex;
  flex-direction: row;
  height: 100vh;
  background-color: #f0f2f5;
}

.pageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}

.content {
  display: flex;
  height: 95vh;
  flex-direction: column;
  overflow-y: auto;
}
// height: calc(95vh - calc(20px + 2rem)); //header height+padding main padding

.flexRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  background: #333;
  color: white;
  padding: 1rem;
  text-align: center;
  margin-top: auto;
}

main {
  flex: 1;
  padding: 1.5rem;
}

.mainContainer {
  display: flex;
  // background-color: bisque;
  flex-direction: column;
  height: 100%;
  // flex: 1;
}

p {
  margin: 0;
}

.spacer {
  flex: 1;
}

.spacer-v {
  height: 30px;
}

.textCenter {
  text-align: center;
}
