.contentHeader {
  align-items: center;
}

.filterDiv {
  // justify-content: space-between;
  display: flex;
}

.tableContainer {
  flex: 1;
  background-color: white;
  border-radius: 5px 5px 0 0;
  overflow-y: auto;
  & table {
    width: 100%;
    border-collapse: collapse;
    & tr:hover {
      background-color: #f5f5f5;
    }
    & th,
    td {
      padding: 10px;
    }
    & th {
      top: 0;
      position: sticky;
      background-color: white;
      &::after {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: #545454;
      }
    }
  }
}

th,
td {
  border-bottom: 1px solid #ddd;
  // padding: 8px;
}

th {
  text-align: left;
}

.imageCell {
  align-content: center;
}

.imageContainer {
  display: flex;
  align-items: center;
  & img {
    width: 40px;
  }
}

.instock {
  width: 110px;
  align-content: center;
  background-color: #31d100;
  color: white;
  border-style: solid;
  border-color: #31d100;
  border-width: 2px;
  border-radius: 100px;
  height: 25px;
}

.outofstock {
  width: 110px;
  align-content: center;
  background-color: #d13100;
  color: white;
  border-style: solid;
  border-color: #d13100;
  border-width: 2px;
  border-radius: 100px;
  height: 25px;
}

.addNewProductModal {
  position: absolute;
  justify-content: center;
  padding: 25px 70px;
  border: 2px solid #cccccc;
  border-radius: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  background-color: white;
}
.modalFormRow {
  width: 100%;
  height: 30px;
  padding: 5px;
  & label {
    width: 150px;
  }
  & input,
  select {
    width: 250px;
    height: 20px;
    padding: 5px;
    outline: none;
    border: 1px solid #707070;
    border-radius: 5px;
  }
}
